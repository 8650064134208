<template>
 <div v-load="loading">
    <b-modal
      :id="modalId"
      :title="modalTitle"
      centered
      title-class="font-22"
      hide-footer
    >
      <Form
        :initialData="currentRow"
        :positions="positions"
        :items="getItems"
        @onSumbit="handleSubmit"
      />
    </b-modal>
    <v-table
      :list="getItems"
      @onEdit="handleEdit"
      @onDelete="handleDelete"
      :header="header"
    >
      <template slot="card-header">
        <div class="d-flex justify-content-between align-items-center mb-0 p-2">
          <h4 class="mb-0">Organization Structure</h4>
          <div class="d-flex justify-content-between">
            <div>
              <router-link to="/state" tag="button" class="btn btn-info mr-3">
                <i class="fas fa-eye"></i>
                Preview
              </router-link>
            </div>
            <b-button
                variant="primary"
                @click="handleAdd"
            >
              <i class="bx bx-list-plus font-size-20 align-middle mr-2"></i>
              Add new structure
            </b-button>
          </div>
        </div>
      </template>
      <template slot="parent" slot-scope="{ item }">
        {{ getParent(item) }}
      </template>
      <template slot="child" slot-scope="{ item }">
        {{ item.position ? item.position.title : '' }}
      </template>
      <template slot="color" slot-scope="{ item }">
        <input type="color" :value="item.color" disabled>
      </template>
      <!-- <template slot="popupcolor" slot-scope="{ item }">
        <input type="color" :value="item.popupcolor" disabled>
      </template> -->
      <template slot="sort" slot-scope="{ item }">
        {{ item.sort }}
      </template>
    </v-table>

  </div>
</template>

<script>
import Form from './form.vue';
import { mapGetters, mapActions } from 'vuex';
import { FORM_MODE } from '@/utils/constants';

export default {
  components: {
    Form,
  },
  data(){
    return {
      loading:true,
      formMode: '',
      currentRow: null,
      modalId: 'orgstructs-form',
      header:[
        {key:'parent',title:'Parent'},
        {key:'child',title:'Child'},
        {key:'color',title:'Color'},
        // {key:'popupcolor',title:'Popup Color'},
        {key:'sort',title:'Sort'},
      ]
    }
  },
  async mounted(){
    await this.items();
    this.loading = false
    await this.statePositions();
  },
  methods: {
    ...mapActions({
      'statePositions':'orgStruct/statePositions',
      'items':'orgStruct/items',
      'create':'orgStruct/create',
      'update':'orgStruct/update',
      'delete':'orgStruct/delete',
    }),
    getParent(item){
      return item?.parent?.position?.title || '---';
    },
    handleEdit(row){
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    async handleDelete(id){
      this.loading = true;
      await this.delete(id);
      await this.items();
      this.loading = false;
    },
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    async handleSubmit(data) {
      this.loading = true;
      if (this.formMode === FORM_MODE.CREATE) {
        await this.create(this.toFormData(data));
      }

      if (this.formMode === FORM_MODE.EDIT) {
        await this.update({
          id: this.currentRow.id,
          data: this.toFormData({...data, '_method':'PUT'})
        });
      }
      await this.items();
      this.$bvModal.hide(this.modalId);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      'positions':'orgStruct/getStatePositions',
      'getItems':'orgStruct/getItems',
      'getMessage':'orgStruct/getMessage',
    }),
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    },
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Organization Structure' : 'Edit Organization Structure';
    },
    disableButton() {
      return !this.form.title;
    }
  }
}
</script>
