<template>
  <div>
    <ModalForm
      :modalId="modalId"
      :formMode="formMode"
      :inititalTitle="currentRow ? currentRow.title : ''"
      modalTitle="Create new status"
      @onSubmit="handleSubmit"
    />
    <SimpleTable
      :list="list"
      :buttonTitle="'Add new status'"
      title="Legal Statuses"
      @onAdd="handleAdd"
      @onEdit="handleEdit"
      @onDelete="handleDelete"
    />
  </div>
</template>

<script>
import ModalForm from '@/components/ModalForm.vue';
import SimpleTable from '@/components/SimpleTable.vue';
import { FORM_MODE } from '@/utils/constants';

export default {
  components: {
    ModalForm,
    SimpleTable,
  },
  data() {
    return {
      formMode: '',
      currentRow: null,
      modalId: 'location-form'
    }
  },
  mounted() {
    this.$store.dispatch('settings/getLegalStatusesList');
  },
  methods: {
    handleEdit(row) {
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    handleDelete(id) {
      this.$store.dispatch('settings/deleteLegalStatus', { data: this.$convertObjectToFormData({ _method: 'DELETE' }), id });
    },
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    handleSubmit(data) {
      if (this.formMode === FORM_MODE.CREATE) {
        this.$store.dispatch('settings/createLegalStatus', this.$convertObjectToFormData(data));
      }

      if (this.formMode === FORM_MODE.EDIT) {
        this.$store.dispatch('settings/updateLegalStatus', {
          id: this.currentRow.id,
          data: this.$convertObjectToFormData({ ...data, _method: 'PUT' })
        });
      }
      this.$bvModal.hide(this.modalId);
    }
  },
  computed: {
    list() {
      return this.$store.state.settings.legalStatuses;
    }
  }
}
</script>
