<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-0 p-2">
      <h4 class="mb-0">{{ title }}</h4>
      <b-button
        variant="primary"
        @click="onAddButtonClick"
      >
        <i class="bx bx-list-plus font-size-20 align-middle mr-2"></i>
        {{ buttonTitle }}
      </b-button>
    </div>
    <div
      v-if="list.length"
      class="table-responsive"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th class="id-column"><div>#</div></th>
            <th>Title</th>
            <th class="actions-buttons">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in list"
            :key="item.id"
          >
            <th class="id-column"><div>{{ index + 1 }}</div></th>
            <td>{{ item.title }}</td>
            <td class="actions-buttons">
              <div>
                <i
                  class="bx bx-pencil text-warning font-size-20 mr-3"
                  @click="onEditIconClick(item)"
                />
                <i
                  class="bx bx-trash text-danger font-size-20"
                  @click="onDeleteIconClick(item)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleTable',
  props: ['list', 'buttonTitle', 'title'],
  methods: {
    onAddButtonClick() {
      this.$emit('onAdd');
    },
    onEditIconClick(item) {
      this.$emit('onEdit', item);
    },
    onDeleteIconClick(row) {
      this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete ${row.title}`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then((value) => {
          if (value) {
            this.$emit('onDelete', row.id);
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .actions-buttons {
    width: 120px;
    text-align: right;
    i {
      cursor: pointer;
    }
  }
</style>
