<template>
  <b-modal
    :id="modalId"
    :title="modalTitle"
    centered
    title-class="font-22"
    hide-footer
  >
    <b-form @submit="handleSubmit">
      <b-form-group
        label="Title"
        label-for="title"
        class="required"
      >
        <b-form-input
          id="title"
          v-model="form.title"
          type="text"
          placeholder="Enter title"
          required
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          :disabled="disableButton"
          type="submit"
          variant="primary"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { FORM_MODE } from '@/utils/constants';

export default {
  name: 'ModalForm',
  props: {
    modalId: String,
    formMode: String,
    modalTitle: String,
    inititalTitle: String
  },
  data() {
    return {
      form: {
        title: ''
      }
    }
  },
  watch: {
    inititalTitle(val = '') {
      this.form.title = val
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.form);
    }
  },
  computed: {
    disableButton() {
      return (this.form.title === this.inititalTitle || !this.form.title);
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    }
  },
}
</script>