<template>
  <div>
    <!--    <ModalForm-->
    <!--      :modalId="dutyFormId"-->
    <!--      :formMode="formMode"-->
    <!--      :inititalTitle="title"-->
    <!--      :modalTitle="dutyFormTitle"-->
    <!--      @onSubmit="handleDutyFormSubmit"-->
    <!--    />-->
    <!--    <ModalForm-->
    <!--      :modalId="instructionFormId"-->
    <!--      :formMode="formMode"-->
    <!--      :inititalTitle="title"-->
    <!--      :modalTitle="instructionFormTitle"-->
    <!--      @onSubmit="handleInstructionFormSubmit"-->
    <!--    />-->
    <b-form @submit="handleSubmit($event)">
      <b-form-group
          label="Title"
          label-for="title"
          class="required"
      >
        <b-form-input
            id="title"
            v-model="form.title"
            type="text"
            placeholder="Enter title"
            required
        />
      </b-form-group>
      <div class="form-group parent">
        <label for="department">Department</label>
        <multiselect
            v-model="form.department"
            :options="departments"
            :searchable="false"
            track-by="id"
            label="title"
            placeholder="Pick a department"
        ></multiselect>
      </div>
<!--      <label v-if="showDutyCreate" class="mb-0">Duty</label>-->
<!--      <div v-if="showDutyCreate" class="d-flex align-items-center justify-content-between mb-4">-->
<!--        <b-form-input-->
<!--            id="duty-title"-->
<!--            class="mr-4"-->
<!--            v-model="title"-->
<!--            type="text"-->
<!--            :placeholder="dutyFormTitle"-->
<!--            @onSubmit="handleDutyFormSubmit(title)"-->
<!--        />-->
<!--        <b-button-->
<!--            @click="handleDutyFormSubmit(title)"-->
<!--            variant="primary"-->
<!--            class="add-duty"-->
<!--        >-->
<!--          Save-->
<!--        </b-button>-->
<!--      </div>-->
<!--      <label v-if="showInstructionCreate" class="mb-0">Instruction</label>-->
<!--      <div v-if="showInstructionCreate" class="d-flex align-items-center justify-content-between mb-4">-->
<!--        <b-form-input-->
<!--            id="duty-title"-->
<!--            class="mr-4"-->
<!--            v-model="title"-->
<!--            type="text"-->
<!--            :placeholder="instructionFormTitle"-->
<!--            @onSubmit="handleInstructionFormSubmit(title)"-->
<!--        />-->
<!--        <b-button-->
<!--            @click="handleInstructionFormSubmit(title)"-->
<!--            variant="primary"-->
<!--            class="add-instruction"-->
<!--        >-->
<!--          Save-->
<!--        </b-button>-->
<!--      </div>-->
<!--      <b-form-group>-->
<!--        <div class="d-flex align-items-center justify-content-between mb-4">-->
<!--          <label class="mb-0">Duties</label>-->
<!--          <b-button-->
<!--              v-show="!showDutyCreate"-->
<!--              @click="onAddDuty"-->
<!--              variant="primary"-->
<!--          >-->
<!--            Add Duty-->
<!--          </b-button>-->
<!--        </div>-->
<!--        <div-->
<!--            v-for="(duty, index) in form.duties"-->
<!--            :key="duty.value + index"-->
<!--            class="duties"-->
<!--        >-->
<!--          <div class="duties__item mb-2">-->
<!--            <div class="duties__item__title">-->
<!--              <span class="font-weight-bold">{{ duty.value }}</span>-->
<!--              <div class="duties__actions">-->
<!--                <i-->
<!--                    v-b-tooltip.hover.top-->
<!--                    title="Add Instruction"-->
<!--                    class="bx bx-list-plus text-success font-size-20 mr-3 icon"-->
<!--                    @click="onInstructionAdd(index)"-->
<!--                />-->
<!--                <i-->
<!--                    v-b-tooltip.hover.top-->
<!--                    title="Edit duty"-->
<!--                    class="bx bx-pencil text-warning font-size-20 mr-3 icon"-->
<!--                    @click="onEditDuty(duty, index)"-->
<!--                />-->
<!--                <i-->
<!--                    v-b-tooltip.hover.top-->
<!--                    title="Delete duty"-->
<!--                    class="bx bx-trash text-danger font-size-20 icon"-->
<!--                    @click="onDeleteDuty(index)"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--                v-for="(instruction, instIndex) in duty.instructions"-->
<!--                :key="instruction.value + instIndex"-->
<!--                class="d-flex align-items-center justify-content-between pl-3 mt-2"-->
<!--            >-->
<!--              <span>{{ instruction.value }}</span>-->
<!--              <div>-->
<!--                <i-->
<!--                    v-b-tooltip.hover.top-->
<!--                    title="Edit instruction"-->
<!--                    class="bx bx-pencil text-warning font-size-20 mr-3 icon"-->
<!--                    @click="onEditInstruction(instruction, index, instIndex)"-->
<!--                />-->
<!--                <i-->
<!--                    v-b-tooltip.hover.top-->
<!--                    title="Delete instruction"-->
<!--                    class="bx bx-trash text-danger font-size-20 icon"-->
<!--                    @click="onDeleteInstruction(index, instIndex)"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </b-form-group>-->
      <div class="d-flex justify-content-end">
        <button
            type="button"
            class="btn btn-primary"
            :disabled="disableButton"
            @click.prevent="handleSubmitClick"
        >
          {{ buttonText }}
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
// import ModalForm from '@/components/ModalForm.vue';
import { FORM_MODE } from '@/utils/constants';
import Multiselect from 'vue-multiselect'
export default {
  name: 'PositionForm',
  props: ['initialData','positions','departments'],
  components: {
    // ModalForm
    Multiselect,
  },
  data() {
    return {
      formMode: null,
      dutyFormId: 'duty-form-id',
      instructionFormId: 'instruction-form-id',
      title: '',
      currentDutyIndex: null,
      currentInstructionIndex: null,
      form: {
        title: '',
        duties: [],
        department:null,
        department_id:null,
      },
      showDutyCreate: false,
      showInstructionCreate: false
    }
  },
  mounted() {
    if (this.initialData) {
      this.form.title = this.initialData.title;
      this.form.department = this.initialData.department;

      this.initialData.duties.forEach((duty) => {
        this.form.duties.push({ ...duty });
      });
    }
  },
  computed: {
    dutyFormTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create new duty' : 'Edit duty';
    },
    instructionFormTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create new instruction' : 'Edit instruction';
    },
    disableButton() {
      let result = this.form.title == '' && this.form.parent == null;
      return result;
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    }
  },
  methods: {
    onlyPositiveNum(){
      if(Number(this.form.sort) < 0 ) this.form.sort = '';
    },
    onInstructionAdd(index) {
      this.title = '';
      this.currentDutyIndex = index;
      this.formMode = FORM_MODE.CREATE;
      // this.$bvModal.show(this.instructionFormId);
      this.showInstructionCreate = true
    },
    onAddDuty() {
      // this.title = '';
      this.formMode = FORM_MODE.CREATE;
      this.showDutyCreate = true
      // this.$bvModal.show(this.dutyFormId);
    },
    handleSubmit(event) {
      event.preventDefault();
    },
    handleSubmitClick() {
      this.form.department_id = this.form.department?.id;
      this.$emit('onSumbit', this.form);
    },
    handleDutyFormSubmit(title) {
      if (this.formMode === FORM_MODE.CREATE) {
        this.form.duties.push({ value: title, instructions: [], order: 1 });
      } else {
        this.$set(this.form.duties[this.currentDutyIndex], 'value', title);
      }
      this.showDutyCreate = false
      this.title = ''
    },
    handleInstructionFormSubmit(title) {
      if (this.formMode === FORM_MODE.CREATE) {
        this.form.duties[this.currentDutyIndex].instructions.push({ value: title });
      } else {
        this.form
            .duties[this.currentDutyIndex]
            .instructions[this.currentInstructionIndex].value = title;
      }
      // this.$bvModal.hide(this.instructionFormId);
      this.showInstructionCreate = false
    },
    onEditDuty(duty, index) {
      this.title = duty.value;
      this.currentDutyIndex = index;
      this.formMode = FORM_MODE.EDIT;
      // this.$bvModal.show(this.dutyFormId);
      this.showDutyCreate = true
    },
    onEditInstruction(instruction, dutyIndex, instIndex) {
      this.title = instruction.value;
      this.currentDutyIndex = dutyIndex;
      this.currentInstructionIndex = instIndex;
      this.formMode = FORM_MODE.EDIT;
      this.showInstructionCreate = true
      // this.$bvModal.show(this.instructionFormId);
    },
    onDeleteDuty(index) {
      this.$bvModal.msgBoxConfirm('Delete this duty?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$delete(this.form.duties, index);
            }
          })
    },
    onDeleteInstruction(dutyIndex, instIndex) {
      this.$bvModal.msgBoxConfirm('Delete this instruction?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.form.duties[dutyIndex].instructions.splice(instIndex, 1);
            }
          })
    }
  }
}
</script>
<style lang="scss" scoped>
.duties {
  &__item {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      width: calc(100% - 100px);
      word-break: break-all;
    }
  }
  &__actions {
    width: 95px;
    i {
      cursor: pointer;
    }
  }
}
.add-duty {
  height: 37px;
  width: 150px;
}
.add-instruction {
  height: 37px;
  width: 200px;
}
.icon {
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
</style>
