<template>
<div class="d-flex flex-column p-4 w-50">
  <div class="d-flex justify-content-between pb-5">
    <div class="d-flex align-content-center justify-content-center">
      <h4 class="pr-3">Edit {{ initialData ? initialData.title : 'Не найдено' }} duties</h4>
    </div>
    <button class="btn btn-primary" @click="$emit('back', 1)">Back</button>
  </div>
  <div class="d-flex flex-column justify-content-center">
    <b-table hover :items="items" :fields="fields" class="table-duties">
      <template #cell(id)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(title)="data" :style="{width: 250+'px'}">
        <div>
          <span v-if="!data.item.status">
            {{ data.item.value }}
          </span>
          <input type="text" v-model="data.item.value" class="form-control" :hidden="!data.item.status">
        </div>
      </template>

      <template #cell(action)="data">
        <a href="" @click.prevent="data.item.status = !data.item.status" v-if="!data.item.status"
        >
          <i
              class="bx bx-pencil text-warning font-size-20 mr-3"
          />
        </a>
        <a href="" v-if="data.item.status" @click.prevent="updateDuties(data)"
        >
          <i
              class="bx bx-check text-success font-size-20 mr-3"
          />
        </a>
        <a href="" @click.prevent="deleteDuties(data.item.id)">
          <i
              class="bx bx-trash text-danger font-size-20"
          />
        </a>
      </template>
    </b-table>
    <div class="d-flex align-content-center mt-4">
      <input type="text" class="form-control  mr-3" placeholder="Add duties" v-model="dutiesValue" @keyup.enter="addDuties()"/>
      <button class="btn btn-primary" @click.prevent="addDuties()">
        Save
      </button>
<!--      <a href="" @click.prevent="addDuties(items[items.length - 1].id)" class="d-flex align-items-center">-->
<!--        <i>-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <rect x="1" y="1" width="22" height="22" rx="7" stroke="#D9D9D9" stroke-width="2"/>-->
<!--            <path d="M12 6V18" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"/>-->
<!--            <path d="M18 12L6 12" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"/>-->
<!--          </svg>-->

<!--        </i>-->
<!--      </a>-->
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "EditDuties",
  props: ['initialData'],
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: '#',
        },
        {
          key: 'title',
          label: 'Duties',
        },
        {
          key: 'action',
          label: 'Action',
        }
      ],
      items: [],
      dutiesValue: null
    }
  },
  methods: {
    async addDuties() {
      if (this.dutiesValue) {
        await this.$store.dispatch('settings/createDuties', this.toFormData({ value: this.dutiesValue, order: 1, position_id:this.initialData.id })).then(() => {
          if (this.getActiveDutiesId) {
            this.items.push({...this.getActiveDutiesId, status: false})
            this.dutiesValue = null
          }
        })
      } else {
        this.$showErrorToast('Empty input for duties')
      }
    },
    async deleteDuties (id) {
      this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$store.dispatch('settings/deleteDuties', {data: this.$convertObjectToFormData({_method: 'DELETE'}), id}).then(() => {
                this.items = this.items.filter(el => el.id !== id)
              })
            }
          })
    },
    filterRow (id) {
      this.items = this.items.filter(el => el.id !== id)
    },
    async updateDuties (data) {
      await this.$store.dispatch('settings/updateDuties', { id:data.item.id, data: this.toFormData({
          value: data.item.value,
          order: data.index + 1,
          position_id: this.initialData.id,
          _method: 'PUT'
        })}
      ).then(() => {
        for (let i=0; i < this.items.length; i += 1 ) {
          if (this.items[i].id === data.item.id) {
            this.items[i].status = false
          }
        }
      })
    }
  },
  mounted() {
    this.items = this.initialData?.duties ? this.initialData.duties.map(item => {
      return { ...item, status:false }
    }) : []
  },
  computed: {
    getActiveDutiesId() {
      return this.$store.state.settings.duties
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
