<template>
  <div>
    <b-form @submit="handleSubmit($event)">
      <div class="form-group parent">
        <label for="parent">Parent</label>
        <multiselect
          v-model="parent"
          :options="parents"
          :searchable="true"
          track-by="id"
          label="title"
          placeholder="Pick a parent"
        ></multiselect>
      </div>
      <div class="form-group parent">
        <label for="child">Child</label>
        <multiselect
          v-model="child"
          :options="child_positions"
          :searchable="true"
          track-by="id"
          label="title"
          placeholder="Pick a child"
        ></multiselect>
      </div>
      <div class="form-group parent">
        <label for="color" class="d-flex justify-content-between">
          <span>
            Color
          </span>
          <div class="">
            <select class="default-colors form-control" v-model="form.color">
              <option value="#FAB37A" selected>Choose a default color!</option>
              <option :value="color" v-for="color in colors" :key="color" :style="{backgroundColor:color}" v-text="color"/>
            </select>
          </div>
        </label>
        <input type="color" placeholder="Enter color" class="form-control" id="color" v-model="form.color" required />
      </div>    
      <!-- <b-form-group
          label="Popup Color"
          label-for="popupcolor"
      >
        <b-form-input
            id="popupcolor"
            v-model="form.popupcolor"
            type="color"
            placeholder="Enter popup color"
            required
        />
      </b-form-group> -->
      <b-form-group
        label="Sort"
        label-for="sort"
      >
        <b-form-input
            id="sort"
            v-model="form.sort"
            type="number"
            placeholder="Enter sort"
            required
            @keyup="onlyPositiveNum"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <button
            type="button"
            class="btn btn-primary"
            :disabled="disableButton"
            @click.prevent="handleSubmitClick"
        >
          {{ buttonText }}
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { FORM_MODE } from '@/utils/constants';
import Multiselect from 'vue-multiselect'
export default {
  name: 'OrgStructureForm',
  props: ['initialData','items','positions'],
  components: {
    // ModalForm
    Multiselect,
  },
  data() {
    return {
      formMode: null,
      title: '',
      form: {
        sort:'',
        color:'',
        parent_id:null,
        popupcolor:'#FAB37A',
        child_id:null,
      },
      parent:null,
      child:null,
    }
  },
  mounted() {
    if (this.initialData) {
      this.form.sort = this.initialData.sort
      this.form.color = this.initialData.color
      // this.form.popupcolor = this.initialData.popupcolor
      this.parent = {
        id:this.initialData?.parent?.id,
        title:this.initialData?.parent?.position?.title,
      };
      this.child = this.initialData.position;
    }
  },
  watch:{
    'parent':{
      handler(){
        if(this.parent?.title == this.child?.title){
          this.child = null;
        }
      },deep:true
    }
  },
  computed: {
    parents(){
      return this.items.map(item=>{
        return {
          title:item.position?.title,
          id:item.id,
        }
      });
    },
    child_positions(){
      let title = this.parent?.title
      if(title)return this.positions.filter(item=>item.title != title);
      return this.positions;
    },
    disableButton(){
      return !this.child || !this.form.sort || !this.form.color || !this.form.popupcolor;
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    },
    colors(){
      return ['#FA8C8C','#FAB37A','#75B1E0','#8499E6','#88D16B'];
    },
  },
  methods: {
    onlyPositiveNum(){
      if(Number(this.form.sort) < 0 ) this.form.sort = '';
    },
    handleSubmit(event) {
      event.preventDefault();
    },
    handleSubmitClick() {
      if(this.child){
        this.form.parent_id = this.parent?.id || '';
        this.form.child_id = this.child?.id || '';
        this.$emit('onSumbit', this.form);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.duties {
  &__item {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      width: calc(100% - 100px);
      word-break: break-all;
    }
  }
  &__actions {
    width: 95px;
    i {
      cursor: pointer;
    }
  }
}
.add-duty {
  height: 37px;
  width: 150px;
}
.add-instruction {
  height: 37px;
  width: 200px;
}
.icon {
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
</style>
