<template>
  <Layout>
    <h3 class="mb-4 mt-3">Settings</h3>
    <b-tabs
      pills
      vertical
      nav-class="card-body"
      nav-wrapper-class="p-0 col-md-3 card"
      content-class="pt-0 px-3 col-md-9"
      v-model="tabIndex"
      class="settings"
    >
      <div class="card">
        <b-tab>
          <template v-slot:title>
            <span>Departments</span>
          </template>
          <DepartmentsTab v-if="tabIndex === 0" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Positions</span>
          </template>
          <PositionsTab v-if="tabIndex === 1" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Categories</span>
          </template>
          <CategoriesTab v-if="tabIndex === 2" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Locations</span>
          </template>
          <LocationsTab v-if="tabIndex === 3" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Payment Schemes</span>
          </template>
          <PaymentShemesTab v-if="tabIndex === 4" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Legal Statuses</span>
          </template>
          <LegalStatusesTab v-if="tabIndex === 5" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Permisssions</span>
          </template>
          <Permission v-if="tabIndex === 6" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Organization Structure</span>
          </template>
          <OrgStruct v-if="tabIndex === 7" />
        </b-tab>
      </div>
    </b-tabs>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main.vue';

import {
  LocationsTab,
  DepartmentsTab,
  PositionsTab,
  CategoriesTab,
  PaymentShemesTab,
  LegalStatusesTab,
  OrgStruct,
  Permission
} from './tabs';

export default {
  name: 'SettingsPage',
  components: {
    Layout,
    LocationsTab,
    DepartmentsTab,
    PositionsTab,
    CategoriesTab,
    PaymentShemesTab,
    LegalStatusesTab,
    Permission,
    OrgStruct,
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  watch:{
    tabIndex:{
      handler(){
        if(this.tabIndex != this.$route.params.index)this.$router.replace({ name:'settings', params:{ index:this.tabIndex } });
      }
    }
  },
  computed: {
    loading() {
      return this.$store.state.settings.loading;
    }
  },
  created(){
    this.tabIndex = Number(this.$route.params.index);
  }
}
</script>

<style>
.settings{
  margin-left: -0px;
}
</style>
