export const FORM_MODE = {
  EDIT: 'edit',
  CREATE: 'create',
  UPLOAD: 'upload'
};

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

export const CHART_BG_COLORS = {
  red: 'rgba(255, 99, 132, 0.3)',
  orange: 'rgba(255, 159, 64, 0.3)',
  yellow: 'rgba(255, 205, 86, 0.3)',
  green: 'rgba(75, 192, 192, 0.3)',
  blue: 'rgba(54, 162, 235, 0.3)',
  purple: 'rgba(153, 102, 255, 0.3)',
  grey: 'rgba(201, 203, 207, 0.3)'
};
