<template>
  <div>
    <b-modal
      :id="modalId"
      :title="modalTitle"
      centered
      title-class="font-22"
      hide-footer
    >
      <b-form @submit="handleSubmit">
        <b-form-group
          label="Title"
          label-for="title"
          class="required"
        >
          <b-form-input
            id="title"
            v-model="form.title"
            type="text"
            placeholder="Enter title"
            required
          />
        </b-form-group>
        <b-form-group
          label="Color"
          label-for="color"
          class="required"
        >
          <b-form-input
            id="color"
            v-model="form.color"
            type="color"
            required
          />
        </b-form-group>
        <b-form-group
          label="Sort"
          label-for="sort"
          class="required"
        >
          <b-form-input
            id="sort"
            v-model="form.sort"
            type="number"
            required
          />
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="primary"
            :disabled="disableButton"
          >
            {{ buttonText }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <SimpleTable
      :list="list"
      :buttonTitle="'Add new department'"
      title="Departments"
      @onAdd="handleAdd"
      @onEdit="handleEdit"
      @onDelete="handleDelete"
    />
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable.vue';
import { FORM_MODE } from '@/utils/constants';

export default {
  components: { SimpleTable },
  data() {
    return {
      form: {
        title: '',
        color: '',
        sort: '',
      },
      formMode: '',
      currentRow: null,
      modalId: 'departments-form'
    }
  },
  mounted() {
    this.$store.dispatch('settings/getDepartmentsList');
  },
  methods: {
    handleEdit(row) {
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    handleDelete(id) {
      this.$store.dispatch('settings/deleteDepartment', { data: this.$convertObjectToFormData({ _method: 'DELETE' }), id });
    },
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.formMode === FORM_MODE.CREATE) {
        this.$store.dispatch('settings/createDepartment', this.$convertObjectToFormData(this.form));
      }

      if (this.formMode === FORM_MODE.EDIT) {
        this.$store.dispatch('settings/updateDepartment', {
          id: this.currentRow.id,
          data: this.$convertObjectToFormData({ ...this.form, _method: 'PUT' })
        });
      }
      this.$bvModal.hide(this.modalId);
    }
  },
  watch: {
    currentRow(row) {
      this.form.title = row ? row.title : '';
      this.form.color = row ? row.color : '';
      this.form.sort = row ? row.sort : '';
    }
  },
  computed: {
    list() {
      return this.$store.state.settings.departments;
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    },
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Department' : 'Edit Department';
    },
    disableButton() {
      if (!this.form.title || !this.form.color) {
        return true;
      }

      if (this.formMode === FORM_MODE.EDIT) {
        return (this.form.title === this.currentRow.title && this.form.color === this.currentRow.color && this.form.sort === this.currentRow.sort);
      }

      return false;
    }
  }
}
</script>
