<template>
  <div>
    <b-modal
      :id="modalId"
      :title="modalTitle"
      centered
      title-class="font-22"
      hide-footer
    >
      <Form
        :initialData="currentRow"
        :positions="list"
        :departments="departments"
        @onSumbit="handleSubmit"
      />
    </b-modal>
    <v-table
        v-if="action === 1"
        :list="list"
        :buttonTitle="'Add new position'"
        title="Positions"
        @onAdd="handleAdd"
        @onEditDuties="handleEditDuties"
        @onEdit="handleEditPosition"
        @onDelete="handleDelete"
        :header="header"
    >
      <template slot="title" slot-scope="{ item }">
        {{item.title}}
      </template>
      <template slot="parent" slot-scope="{ item }">
        {{ item.parent ? item.parent.title : '' }}
      </template>
      <template slot="department" slot-scope="{ item }">
        {{ item.department ? item.department.title : '' }}
      </template>
      <template slot="color" slot-scope="{ item }">
        <input type="color" :value="item.color" disabled>
      </template>
      <template slot="popupcolor" slot-scope="{ item }">
        <input type="color" :value="item.popupcolor" disabled>
      </template>
      <template slot="sort" slot-scope="{ item }">
        {{ item.sort }}
      </template>
      <template slot="icons" slot-scope="{ item }">
        <i
            title="Add or edit duties"
            class="bx bx-list-ul text-warning font-size-20 mr-3"
            @click="handleEditDuties(item)"
        />
      </template>
    </v-table>
    <EditDuties v-if="action === 2" @back="(status) => {action = status}" :initialData="currentRow" />

  </div>
</template>

<script>
import Form from './form.vue';
import EditDuties from "../../../../../components/settings/tabs/positions/EditDuties";
import { FORM_MODE } from '@/utils/constants';

export default {
  components: {
    Form,
    EditDuties
  },
  data() {
    return {
      formMode: '',
      currentRow: null,
      modalId: 'departments-form',
      header:[
        {key:'title',title:'Title'},
        {key:'department',title:'Department'},
      ],
      action: 1 // 1- index page; 2-edit duties page
    }
  },
  mounted() {
    this.$store.dispatch('settings/getPositionsList');
    this.$store.dispatch('settings/getDepartmentsList');
  },
  methods: {
    handleEditDuties(row){
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.action = 2
    },
    handleEditPosition (row) {
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    handleDelete(id) {
      this.$store.dispatch('settings/deletePosition', { data: this.toFormData({ _method: 'DELETE' }), id });
    },
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    async handleSubmit(data) {
      if (this.formMode === FORM_MODE.CREATE) {
        await this.$store.dispatch('settings/createPosition', this.toFormData(data));
      }

      if (this.formMode === FORM_MODE.EDIT) {
        await this.$store.dispatch('settings/updatePosition', {
          id: this.currentRow.id,
          data: this.toFormData({...data, '_method':'PUT'})
        });
      }
      this.$bvModal.hide(this.modalId);
    }
  },
  computed: {
    list() {
      return this.$store.state.settings.positions;
    },
    departments() {
      return this.$store.state.settings.departments;
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    },
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Position' : 'Edit Position';
    },
    disableButton() {
      return !this.form.title;
    }
  }
}
</script>
